<template>
  <b-jumbotron
    header="Agent Dash Login Success"
    lead="Login Success! Logging into Aira."
  >
    <p> Please proceed to Agent Dashboard. You may close this page. </p>
    <br>
    <br>
    <img
      src="../assets/aira-cat-dash.png"
      alt="Cat on dash courtesy of the QA team: Puck, Rob, Kyle, Molly"
    >
  </b-jumbotron>
</template>

<script>
export default {
  name: 'AgentDashSSOConfirmation',
};
</script>
